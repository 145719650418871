import 'core-js/stable';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

import './sass/base.scss';

window.renderTargetsMicroFrontend = (containerId, history, context) => {
  ReactDOM.render(
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App history={history} context={context} />
        </Provider>
      </I18nextProvider>
      ,
    </BrowserRouter>,
    document.getElementById(containerId),
  );
};

window.unmountTargetsMicroFrontend = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

registerServiceWorker();
