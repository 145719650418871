import lodash from 'lodash';
import Targets from './Containers/Targets';

const makeRoutes = () => [
  {
    path: '/targets',
    exact: true,
    component: Targets,
    permission: ['teamTargets.access']
  }
];

const routePermitted = (routePermission, permission) => routePermission.every((r) => {
  if (!lodash.get(permission, r)) {
    return false;
  }
  return true;
});

export const routesWithPermission = (permission, fallbackComponent) => {
  const PermissionDenied = () => fallbackComponent; 

  const routes = makeRoutes();

  routes.forEach((route) => {
    if (!routePermitted(route.permission, permission)) {
      route.component = PermissionDenied;
    }
  });

  return routes
};
